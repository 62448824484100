import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ooTextWidth, grey7 } from "../element/theme";
import { OoTypoGrey } from "../element/page-element/OoTypo";
import shieldAndSword from "../../res/icon-oberion/shield-and-sword.min.svg";
import powerfulSearch
  from "../../res/illustration/user-story-knowledge-gaming-market-v2-OberionIO.min.svg";
import curatedStorefront
  from "../../res/illustration/user-story-curated-storefront-v2-OberionIO.min.svg";
import nicheGames
  from "../../res/illustration/user-story-find-assess-niche-games-v2-OberionIO.min.svg";

const FeaturesSection = styled.section`
  margin-top: ${props => props.marginTop};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
const TopFeature = styled.div`
  max-width: ${ooTextWidth};
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
const ImageTop = styled.img`
  height: 5rem;
`;
const ImageFeatures = styled.img`
  width: 100%;
  max-width: ${ooTextWidth / 2}rem;
`;
const GridContainer = styled(Grid)`
  padding-top: 4rem;
`;
const GridItem = styled(Grid)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;

const headings = [
  "It's dangerous to go alone! Take this tour.",
  "Oberion is in early access. Come along with us while we create Game Discovery.",
  "Powerful search across multiple stores",
  "Curated storefront",
  "Find niche games and assess them at a glance",
];
const text = [
  "Search for cool new games across multiple stores and platforms. Discover your next favorite game here on Oberion but buy it at your favorite store.",
  "Use Oberion to browse for new games without stumbling upon hundreds of shovelware games. Get a curated selection of the best new indie games.",
  "Get a detailed description of an interesting game, see its features at a glance, and how it compares to your favorite games. Use Oberion to make an informed decision whether to buy a game or not.",
];

const Features = ({ marginTop }) => {
  return (
    <FeaturesSection marginTop={marginTop}>
      <TopFeature>
        <ImageTop src={shieldAndSword} alt="Shield and Sword" />
        <Typography variant="h3" paragraph>{headings[0]}</Typography>
        <OoTypoGrey variant="subtitle1">{headings[1]}</OoTypoGrey>
      </TopFeature>

      <GridContainer container spacing={0} justify="space-between" align="stretch">
        <GridItem item xs={12} sm={6}>
          <Typography variant="h5" paragraph>{headings[2]}</Typography>
          <OoTypoGrey variant="body1">{text[0]}</OoTypoGrey>
        </GridItem>
        <Grid item xs={12} sm={6}>
          <ImageFeatures src={powerfulSearch} alt="Powerful Search" />
        </Grid>
      </GridContainer>

      <GridContainer container spacing={0} direction="row-reverse" justify="space-between"
                     align="stretch">
        <GridItem item xs={12} sm={6}>
          <Typography variant="h5" paragraph>{headings[3]}</Typography>
          <OoTypoGrey variant="body1">{text[1]}</OoTypoGrey>
        </GridItem>
        <Grid item xs={12} sm={6}>
          <ImageFeatures src={curatedStorefront} alt="Curated Storefront" />
        </Grid>
      </GridContainer>

      <GridContainer container spacing={0} justify="space-between" align="stretch">
        <GridItem item xs={12} sm={6}>
          <Typography variant="h5" paragraph>{headings[4]}</Typography>
          <OoTypoGrey variant="body1">{text[2]}</OoTypoGrey>
        </GridItem>
        <Grid item xs={12} sm={6}>
          <ImageFeatures src={nicheGames} alt="Niche Games" />
        </Grid>
      </GridContainer>

    </FeaturesSection>
  );
};
Features.defaultProps = {
  marginTop: "5rem",
};
Features.propTypes = {
  marginTop: PropTypes.string,
};
export default Features;