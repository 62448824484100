import React from "react";
import Typography from "@material-ui/core/Typography";
import { A_REL, OutboundLinkThemed } from "../navigation/ExternalLink";
import LayoutColumn from "../element/layout/LayoutColumn";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import Testimonials from "../home/Testimonials";


const InspirationsSection = styled.div`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginTop};
`;

const inspirationsHeading = "Excellent inspirations";

const Inspirations = ({marginTop}) => {
  return (
    <InspirationsSection marginTop={marginTop}>
      <Typography variant="h3" gutterBottom>{inspirationsHeading}</Typography>
      <Typography variant="body1">
        <li>
          <OutboundLinkThemed href="https://steamspy.com"
                              caption="SteamSpy"
                              rel={A_REL.ENDORSE} lightTheme />
        </li>
        <li>
          <OutboundLinkThemed href="https://steamdb.info"
                              caption="SteamDB"
                              rel={A_REL.ENDORSE} lightTheme />
        </li>
        <li>
          <OutboundLinkThemed href="https://www.goodreads.com"
                              caption="Goodreads"
                              rel={A_REL.ENDORSE} lightTheme />
        </li>
        <li>
          <OutboundLinkThemed href="https://github.com/Depressurizer/Depressurizer"
                              caption="Depressurizer"
                              rel={A_REL.ENDORSE} lightTheme />
        </li>
      </Typography>
    </InspirationsSection>
  );
};
Inspirations.defaultProps = {
  marginTop: "5rem",
};
Inspirations.propTypes = {
  marginTop: PropTypes.string,
};
export default Inspirations;