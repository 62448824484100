import React from "react";
import * as PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const MissionSection = styled.section`margin-top: ${props => props.marginTop};`;

const missionHeading = "Mission";
const missionText0 = "The goal is to raise awareness for innovative but hidden indie gems.";
const missionText1 = "Too many high-quality indie games go unnoticed. Passionate developers who have put hundreds and thousands of hours of development time into their games should not be forced to abandon video games simply because nobody knows about their projects. Oberion solves this challenge which has been described as the \"discoverability problem\".";
const missionText2 = "Players who like niche games or just crazy new indie games should not spend hours searching for that one game that will blow their mind again. Oberion makes your gaming library more transparent, easily browsable, and similar games comparable at a glance.";
const missionText3 = "The scattered landscape of different online stores is brought together here at Oberion.";

const Mission = ({ marginTop }) => {
  return (
    <MissionSection marginTop={marginTop}>
      <Typography variant="h3" gutterBottom>{missionHeading}</Typography>
      <Typography variant="subtitle2" paragraph>{missionText0}</Typography>
      <Typography variant="body1" paragraph>{missionText2}</Typography>
      <Typography variant="body1" paragraph>{missionText1}</Typography>
      <Typography variant="subtitle2" paragraph>{missionText3}</Typography>
    </MissionSection>
  );
};
Mission.defaultProps = {
  marginTop: "5rem",
};
Mission.propTypes = {
  marginTop: PropTypes.string,
};
export default Mission;