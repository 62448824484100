import React from "react";
import Layout from "../components/element/layout/Layout";
import LayoutSetBackground from "../components/element/layout/LayoutSetBackground";
import PageBanner from "../components/element/page-element/PageBanner";
import theme, { ooTextWidth, grey8 } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import SEO from "../components/element/SEO";
import Inspirations from "../components/about/Inspirations";
import Team from "../components/about/Team";
import Mission from "../components/about/Mission";
import Features from "../components/about/Features";
import SocialMediaTable from "../components/element/social-media/SocialMediaTable";

const seo = {
  title: "About - Oberion",
  description: "About Oberion",
};
const aboutHeading = "About";
const aboutTagline = "Oberion creates GAME DISCOVERY";
const marginTop = "5rem";

const About = () => (
  <Layout>
    <SEO {...seo} />
    <PageBanner heading={aboutHeading} subheading={aboutTagline} />
    <LayoutSetBackground backgroundColor={grey8} color={theme.palette.secondary.contrastText}>
      <LayoutColumn alignItems="flex-start"
                    justifyContent="center"
                    textAlign="left"
                    maxWidth={ooTextWidth}
                    paddingBottom="0"
                    paddingTop="0">

        <Features marginTop={marginTop} />
        <Mission marginTop={marginTop} />
        <Team marginTop={marginTop} />
        <SocialMediaTable />
        <Inspirations marginTop={marginTop} />

      </LayoutColumn>
    </LayoutSetBackground>
  </Layout>
);
export default About;
