/**
 * @param obj An array or object containing other objects.
 * @return One random object in the supplied array.
 */
export const pickRandomly = (obj) => {
  const keys = Object.keys(obj);
  return obj[keys[keys.length * Math.random() << 0]]; // eslint-disable-line
};

/**
 * Add leading zero padding to a number.
 */
export const addPadding = (number, digits, paddingChar) => {
  const paddedChar = paddingChar || "0";
  const nr = `${number}`;
  return nr.length >= digits ? nr : new Array(digits - nr.length + 1).join(paddedChar) + nr;
};
