import React from "react";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { StyledTableCss, StyledTdNoBorder } from "../page-element/StyledTable";
import { A_REL, OutboundLinkThemed } from "../../navigation/ExternalLink";
import SocialMediaButton, { CHANNELS } from "./SocialMediaButton";

export const CenteredTextTable = styled(StyledTableCss)`
  text-align: center;
`;

const SocialMediaTable = () => (
    <Typography component="span" variant="body1">
      <CenteredTextTable>
        <tbody>
        <tr>
          <StyledTdNoBorder>
            <OutboundLinkThemed caption={CHANNELS.TWITTER.name} href={CHANNELS.TWITTER.url} lightTheme
                                rel={A_REL.ENDORSE} />
          </StyledTdNoBorder>
          <StyledTdNoBorder>
            <OutboundLinkThemed caption={CHANNELS.BLOG.name} href={CHANNELS.BLOG.url} lightTheme
                                rel={A_REL.ENDORSE} />
          </StyledTdNoBorder>
          <StyledTdNoBorder>
            <OutboundLinkThemed caption={CHANNELS.RSS.name} href={CHANNELS.RSS.url} lightTheme
                                rel={A_REL.ENDORSE} />
          </StyledTdNoBorder>
          <StyledTdNoBorder>
            <OutboundLinkThemed caption={CHANNELS.MEDIUM.name} href={CHANNELS.MEDIUM.url} lightTheme
                                rel={A_REL.ENDORSE} />
          </StyledTdNoBorder>
          {/*<StyledTdNoBorder>*/}
          {/*  <ExternalHref caption="Reddit" href={CHANNELS.REDDIT.url} lightTheme*/}
          {/*                rel={A_REL.ENDORSE} />*/}
          {/*</StyledTdNoBorder>*/}
          {/*<StyledTdNoBorder>*/}
          {/*  <ExternalHref caption="Discord" href={CHANNELS.DISCORD.url} lightTheme*/}
          {/*                rel={A_REL.ENDORSE} />*/}
          {/*</StyledTdNoBorder>*/}
          {/*<StyledTdNoBorder>*/}
          {/*  <ExternalHref caption={CHANNELS.PATREON.name} href={CHANNELS.PATREON.url} lightTheme*/}
          {/*                rel={A_REL.ENDORSE} />*/}
          {/*</StyledTdNoBorder>*/}
        </tr>
        <tr>
          <StyledTdNoBorder>
            <SocialMediaButton channel={CHANNELS.TWITTER} unpadded />
          </StyledTdNoBorder>
          <StyledTdNoBorder>
            <SocialMediaButton channel={CHANNELS.BLOG} unpadded />
          </StyledTdNoBorder>
          <StyledTdNoBorder>
            <SocialMediaButton channel={CHANNELS.RSS} unpadded />
          </StyledTdNoBorder>
          <StyledTdNoBorder>
            <SocialMediaButton channel={CHANNELS.MEDIUM} unpadded />
          </StyledTdNoBorder>
          {/*<StyledTdNoBorder*/}
          {/*><SocialMediaButton channel={CHANNELS.REDDIT} unpadded />*/}
          {/*</StyledTdNoBorder>*/}
          {/*<StyledTdNoBorder>*/}
          {/*  <SocialMediaButton channel={CHANNELS.DISCORD} unpadded />*/}
          {/*</StyledTdNoBorder>*/}
          {/*<StyledTdNoBorder>*/}
          {/*  <SocialMediaButton channel={CHANNELS.PATREON} unpadded />*/}
          {/*</StyledTdNoBorder>*/}
        </tr>
        </tbody>
      </CenteredTextTable>
    </Typography>
);

export default SocialMediaTable;