import React from "react";
import * as PropTypes from "prop-types";
import { Grid, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import OoAvatar from "../element/graphical/OoAvatar";
import thomasAvatar from "./visual/team-thomas-uta-avatar-192px.jpg";
import oezkanAvatar from "./visual/team-oezkan-isik-avatar-OberionIO.png";


const TeamSection = styled.section`
  margin-top: ${props => props.marginTop};
  width: 100%;
  padding: 0 0.5rem;
`;
const followMeHeading = "What do you think? Tell us more on:";
const thomas = [
  "Thomas Uta",
  "Founder & Developer",
];
const oezkan = [
  "Özkan Isik",
  "Founder & Designer",
];

const useStyles = makeStyles(theme => ({
  gridContainer: {
    margin: "3rem 0",
    // [theme.breakpoints.down("xs")]: { padding: "2rem" },
  },
  paper: {
    margin: "0 -0.5rem",
    padding: "2rem",
    // [theme.breakpoints.down("sm")]: { padding: "3rem 6rem" },
    // [theme.breakpoints.down("xs")]: { padding: "2rem 2rem" },
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    color: theme.palette.secondary.contrastText,
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
  },

}));

const Team = ({ marginTop }) => {
  const classes = useStyles();
  return (
    <TeamSection marginTop={marginTop}>
      <Typography variant="h3">Team</Typography>

      <Grid container spacing={2} justify="space-between" alignItems="stretch" className={classes.gridContainer}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <OoAvatar src={thomasAvatar} alt={"Thomas Uta"} />
            <Typography variant="h5" paragraph>{thomas[0]}</Typography>
            <Typography variant="body2">{thomas[1]}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Typography component="h3" variant="h6">{followMeHeading}</Typography>
    </TeamSection>
  );
};
Team.defaultProps = {
  marginTop: "5rem",
};
Team.propTypes = {
  marginTop: PropTypes.string,
};
export default Team;