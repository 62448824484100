import React from "react";
import styled from "styled-components";
import { Grid, Paper, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import GatsbyImage from "gatsby-image";
import { A_REL, LINK_TO, OutboundLinkNoStyle } from "../navigation/ExternalLink";
import { pickRandomly } from "../util/utilityFunctions";
import { ooContentWidth, ooTextWidth } from "../element/theme";

const logoData = [
  {
    id: 1,
    originalName: "",
    alt: "Recipient of the EXIST Business Startup Grant from February 2018 to January 2019",
    link: LINK_TO.exist,
    imageStyle: {
      width: "10rem",
    },
  },
  {
    id: 2,
    originalName: "",
    alt: "Powered by research from Technische Universität Braunschweig",
    link: LINK_TO.tubs,
    imageStyle: {
      width: "11rem",
    },
  },
  {
    id: 3,
    originalName: "",
    alt: "Participant in the borek.digital accelerator batch #5",
    link: LINK_TO.borek,
    imageStyle: {
      width: "12rem",
    },
  },
];

const userFeedback = [
  {
    text: "So I did play 6 out of the 7 games and I like all of them. Just played the demo of the only one left (Titans Souls) and I do like it too. Overall pretty good choices.",
    identity: "- medium-asks7",
  },
  {
    text: "This was very organized and it fit my needs perfectly! I can't wait to look into these new games and add them to my list, thank you!",
    identity: "- Z3nGard3n",
  },
  {
    text: "Thank you. You nailed it on the head with me liking story based games, action and kinda retro I more just like pixel art. Once again thank you been wanting something to play while I wait for the next crosscode update.",
    identity: "- Crosscode_Lover",
  },
  {
    text: "Thanks for putting this together, it was so well done, and I'm definitely going to research some more, then buy them, they all are right up my alley!",
    identity: "- spill_17",
  },
  {
    text: "This was very organized and it fit my needs perfectly! I can't wait to look into these new games and add them to my list, thank you!",
    identity: "- BatBatman",
  },
];

const TestimonialSection = styled.section`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginTop};
  max-width: ${ooContentWidth}rem;
`;
const LogoDiv = styled.div`
  height: 7rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles(theme => ({
  paperLogo: {
    margin: "0 auto",
    padding: "1rem",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    color: theme.palette.secondary.contrastText,
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
  },
  paperUser: {
    margin: "0 auto",
    padding: "3rem 9rem",
    [theme.breakpoints.down("sm")]: { padding: "3rem 6rem" },
    [theme.breakpoints.down("xs")]: { padding: "2rem 2rem" },
    width: "100%",
    backgroundColor: "#ffffff",
    color: theme.palette.secondary.contrastText,
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItem: {
    marginTop: "1.5rem",
  },
}));

const heading = [
  "Our supporters",
  "What gamers are saying",
];


const Testimonials = ({ logos, marginTop }) => {
  const classes = useStyles();
  const logoArray = logos.map((el, index) => logos[index].node.childImageSharp.fluid);
  const feedback = pickRandomly(userFeedback);

  return (
    <TestimonialSection marginTop={marginTop}>
      <Typography variant="h3" color="inherit">{heading[0]}</Typography>

      <Grid container spacing={2} justify="space-evenly" alignItems="stretch">

        {logoArray.map((el, index) => (
          <Grid item xs={12} sm={6} md={4} className={classes.gridItem}
                key={el.originalName + index}>
            <Paper className={classes.paperLogo}>
              <LogoDiv>
                <OutboundLinkNoStyle href={logoData[index].link} rel={A_REL.ENDORSE}>
                  <GatsbyImage fluid={el} alt={el.originalName}
                               style={logoData[index].imageStyle} />
                </OutboundLinkNoStyle>
              </LogoDiv>

              <Typography variant="body2" color="inherit">{logoData[index].alt}</Typography>
            </Paper>
          </Grid>
        ))}

        <Grid item xs={12} className={classes.gridItem}>
          <Paper className={classes.paperUser}>
            <Typography variant="h3" paragraph>{heading[1]}</Typography>
            <Typography variant="body2" paragraph>{feedback.text}</Typography>
            <Typography variant="caption">{feedback.identity}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </TestimonialSection>
  );
};
Testimonials.defaultProps = {
  marginTop: "5rem",
};
Testimonials.propTypes = {
  logos: PropTypes.array.isRequired,
  marginTop: PropTypes.string,
};
export default Testimonials;